<template>
  <div>
    <p-app-bar />

    <v-main>
      <v-container :fluid="!$vuetify.breakpoint.xl">
        <router-view></router-view>
      </v-container>
    </v-main>

    <p-footer />
  </div>
</template>

<script>
import pAppBar from './components/app-bar.vue';
import pFooter from './components/footer.vue';

export default {
  name: 'promoter.layout',
  components: {
    pAppBar,
    pFooter,
  },
};
</script>
