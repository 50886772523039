<template>
  <v-app-bar app rounded color="light">
    <v-list-item class="flex-none pa-0">
      <v-list-item-icon class="mr-4" style="cursor: pointer" @click="pageChange()">
        <tt-icon width="40" icon="logo" />
      </v-list-item-icon>

      <v-list-item-content class="hidden-xs-only">
        <v-list-item-title class="primary--text text-h6 text-bold">
          TICKET TEXT
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-spacer />

    <timer class="center" />

    <v-spacer />

    <div v-if="!$user" class="hidden-xs-only">
      <v-btn text class="ml-2" href="https://tickettext.zendesk.com/hc/en-us" target="_blank"> Help </v-btn>
      <v-btn color="primary" class="ml-2" :to="{ name: 'promoter.login' }"> Create Event </v-btn>
      <v-btn v-if="!isEventPage" text color="primary" class="ml-2" :to="{ name: 'visitor.login' }"> Login </v-btn>
      <v-btn v-if="isEventPage" text color="primary" class="ml-2" @click="$root.$emit('visitor-login')"> Login </v-btn>
      <!-- @click="$root.$emit('promoter-login')" -->
    </div>

    <div v-if="$user">
      <v-list-item dense class="text-right pa-0">
        <v-list-item-content class="hidden-xs-only">
          <v-list-item-title v-if="$user.role === 'temporary'" class=" text-bold">
            Guest User
          </v-list-item-title>
          <v-list-item-title class=" text-bold">
            {{ $user.firstName && $user.lastName ? `${$user.firstName} ${$user.lastName}` : $user.email }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ $user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-menu offset-y min-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-avatar v-bind="attrs" v-on="on">
              <v-img v-if="$user.image" :src="$getProfile200Image($user._id, $user.image)" />
              <v-img v-if="!$user.image" src="@/assets/images/promotor.svg" />
            </v-list-item-avatar>
          </template>
          <v-list dense>
            <v-list-item v-for="item in items" :key="item.title" :to="item.link">
              <v-list-item-icon class="mr-4">
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="$logout()">
              <v-list-item-icon class="mr-4">
                <v-icon color="primary">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </div>

    <v-menu v-if="!$user" offset-y min-width="200">
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon v-bind="attrs" v-on="on" class="hidden-sm-and-up" color="primary"></v-app-bar-nav-icon>
      </template>
      <v-list dense>
        <v-list-item @click="$root.$emit('visitor-login')">
          <v-list-item-icon class="mr-4">
            <v-icon color="primary">mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import timer from './timer';

export default {
  components: {
    timer,
  },
  data() {
    return {
      items: [],
      isEventPage: false,
    };
  },
  mounted() {
    this.setHeaders();
    if (this.$route.name === 'visitor.event') {
      this.isEventPage = true;
    }
    this.$root.$on('login', () => {
      this.setHeaders();
    });
  },
  methods: {
    setHeaders() {
      if (this.$user?.role === 'promoter') {
        this.items = [
          { title: 'Dashboard', icon: 'mdi-home-outline', link: '/promoter/dashboard', divider: true },
          { title: 'Event', icon: 'mdi-calendar', link: '/promoter/events' },
          { title: 'Venues', icon: 'mdi-map-marker-outline', link: '/promoter/venues' },
          { title: 'Promo code', icon: 'mdi-tag-outline', link: '/promoter/promo-codes' },
          // { title: 'Add Ons', icon: 'mdi-plus-outline', link: '/promoter/add-ons' },
          { title: 'Account settings', icon: 'mdi-account-circle-outline', link: '/promoter/account' },
          { title: 'Report', icon: 'mdi-chart-line', link: '/promoter/reports' },
        ];
      } else if (this.$user?.role === 'admin') {
        this.items = [
          { title: 'Dashboard', icon: 'mdi-home-outline', link: '/admin/dashboard', divider: true },
          { title: 'Add Ons', icon: 'mdi-plus-outline', link: '/admin/add-ons' },
          { title: 'Users', icon: 'mdi-account-outline', link: '/admin/users' },
          { title: 'Performers', icon: 'mdi-account-music-outline', link: '/admin/performers' },
          { title: 'Promoters', icon: 'mdi-account-edit-outline', link: '/admin/promoters' },
          { title: 'Events', icon: 'mdi-calendar', link: '/admin/events' },
          { title: 'Event types', icon: 'mdi-calendar-edit', link: '/admin/event-type' },
          { title: 'Event category', icon: 'mdi-calendar-multiple', link: '/admin/event-category' },
          { title: 'Event sub category', icon: 'mdi-calendar-multiple-check', link: '/admin/event-sub-category' },
          { title: 'Event series', icon: 'mdi-calendar-month-outline', link: '/admin/event-series' },
          { title: 'Venues', icon: 'mdi-map-marker-outline', link: '/admin/venues' },
          { title: 'Subscriptions', icon: 'mdi-account-convert-outline', link: '/admin/subscription' },
          { title: 'Suppliers', icon: 'mdi-cart-outline', link: '/admin/suppliers' },
          { title: 'Promo codes', icon: 'mdi-tag-outline', link: '/admin/promo-codes' },
          { title: 'Ticket category ', icon: 'mdi-ticket-confirmation-outline', link: '/admin/ticket-category' },
          { title: 'Third Party', icon: 'mdi-account-multiple', link: '/admin/third-party' },
          { title: 'Seating plans ', icon: 'mdi-floor-plan', link: '/admin/seating-plan' },
          { title: 'Seats io designer', icon: 'mdi-seat-outline', link: '/admin/seats-io-designer' },
          { title: 'Reports', icon: 'mdi-chart-line', link: '/admin/reports' },
          { title: 'Customer Order History', icon: 'mdi-sale', link: '/admin/sales-reports' },
          { title: 'Custom Widget', icon: 'mdi-widgets', link: '/admin/custom-widget' }
        ];
      } else if (this.$user?.role === 'user') {
        this.items = [{ title: 'Account settings', icon: 'mdi-account-circle-outline', link: '/account' }];
      } else if (this.$user?.role === 'venue') {
        this.items = [
          { title: 'Events', icon: 'mdi-calendar', link: '/venue/events' },
          { title: 'Venues', icon: 'mdi-map-marker-outline', link: '/venue/venues' },
          { title: 'Order history', icon: 'mdi-tag-outline', link: '/venue/order-history' },
          { title: 'Account settings', icon: 'mdi-account-circle-outline', link: '/venue/account' }
        ]
      }
    },
    pageChange() {
      this.manageUserActivity({event:'app logo clicked'});
      if (!this.$user || this.$user?.role === 'user' || this.$user?.role === 'temporary') {
        this.$next('/');
      } else if (this.$user?.role === 'promoter') {
        this.$next('/promoter/dashboard');
      } else if (this.$user?.role === 'admin') {
        this.$next('/admin/dashboard');
      } else if (this.$user?.role === 'venue') {
        this.$next('/venue/events');
      }
    },
  },
  watch:{
    $route (to, from){
      if (this.$route.name === 'visitor.event') {
        this.isEventPage = true;
      } else {
        this.isEventPage = false;
      }
    }
} 
};
</script>
