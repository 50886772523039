<template>
  <div v-if="showTimer" class="d-flex">
    <vue-countdown-timer
      @start_callback="startCallBack()"
      @end_callback="endCallBack()"
      :start-time="startTime"
      :end-time="endTime"
      :interval="1000"
    >
      <template slot="countdown" slot-scope="scope">
        <span class="primary--text text-bold">{{ scope.props.minutes }}:{{ scope.props.seconds }} </span>
      </template>
    </vue-countdown-timer>

    <v-tooltip bottom v-if="!iscarthide">
      <template v-slot:activator="{ on, attrs }">
        <v-icon color="primary" v-bind="attrs" v-on="on"  class="ml-2" @click="gotoBucket()">
          mdi-cart-outline
        </v-icon>
      </template>
      <span>Basket</span>
    </v-tooltip>
  </div>
</template>

<script>
import moment from 'moment';
import { PromoterEventService, AddonService, TickerBookingService } from '@services';
export default {
  props: {
    iscarthide: String,
  },
  data() {
    return {
      startTime: null,
      endTime: null,
      showTimer: false,
      currentime: null,
    };
  },
  created() {
    this.setTimer();
  },
  mounted() {
    this.$root.$on('start-timer', () => {
      
      this.startTimer();
    });

    this.$root.$on('stop-timer', () => {
      this.stopTimer();
    });

    this.$root.$on('addTimeToCart', () => {
      this.addTime();
    });
  },
  methods: {
    getTime() {
      if (this.endTime) {
        var startDate = new Date();
        var endDate   = new Date(this.endTime);
        var seconds = parseInt((endDate.getTime() - startDate.getTime()) / 1000);
        if (seconds == 60 || seconds == 59) {
          this.$alert({
            title: `Your basket is going to expire in 1 minute.To reserve your tickets please make payment before the basket expires.`,
          });
        }

        if (seconds === 0) {
          clearInterval(this.interval);
        }
      }
    },
    gotoBucket() {
      this.$root.$emit('bookingPageChange', 'ticketselect');
      this.manageUserActivity({event:'cart clicked'});
      if (window.localStorage.getItem('bookedEvent') !== this.$route.params.id) {
        setTimeout(() => {
          // window.location.reload();
          //window.location.reload();
        }, 100);
      }
      const query = Object.assign({}, this.$route.query);
      query.page = 'ticketselect'
      this.$next({
        name: 'visitor.event',
        params: { id: window.localStorage.getItem('bookedEvent') },
        query: query,
      });
    },
    setTimer() {
      const startTime = localStorage.getItem('startTime');

      if (moment(startTime).isValid()) {
        this.startTimer(startTime);
      }
    },
    startTimer(startTime) {
      this.showTimer = true;

      this.startTime = moment(startTime);
      this.endTime = moment(this.startTime).add(process.env.VUE_APP_TIMER || 7, 'minutes');
      if (!this.iscarthide) {
        this.interval = setInterval(() => this.getTime(), 2000);
      }

      localStorage.setItem('startTime', this.startTime);
    },
    stopTimer() {
      this.showTimer = false;
      this.startTime = null;
      this.endTime = null;
      localStorage.setItem('startTime', null);
      clearInterval(this.interval);
    },
    startCallBack() {
      this.$root.$emit('on-timer-start');
    },
    async endCallBack() {
      this.showTimer = false;
      const selectedSeats = JSON.parse(window.localStorage.getItem('selectedSeats'));
      if ((selectedSeats || []).length) {
        const data = [];
        const times = [];
        for (let index = 0; index < selectedSeats.length; index++) {
          const element = selectedSeats[index];
          const findIndex = data.findIndex(d => d.id === element._id);
          times.push(element.time)
          if (findIndex === -1) {
            data.push({
              id: element._id,
              bucketTickets: 1,
            });
          } else {
            data[findIndex].bucketTickets = data[findIndex].bucketTickets + 1;
          }
        }
        await TickerBookingService.removeFromCart({ eventTicketCategoryIds: data, userId: this.$user._id, times });
        window.localStorage.removeItem('selectedSeats');
      }

      const selectedAddson = JSON.parse(window.localStorage.getItem('selectedAddson'));
      const eventId = window.localStorage.getItem('eventId')
      if (selectedAddson && selectedAddson.length && eventId) {
        const data = [];
        const times = [];
        for (let index = 0; index < selectedAddson.length; index++) {
          const element = selectedAddson[index];
          times.push(element.time);
          data.push({
            id: element.addon._id,
            bucketTickets: 1,
            attributeId: element.selectedAttribute.id
          });
        }
        await AddonService.releaseTicket({ addsonsIds: data, event: eventId, times})
        window.localStorage.removeItem('selectedAddson');
      }

      // this.$root.$emit('seatChange', this.selectedSeats);
      this.$root.$emit('stop-timer');
      this.$root.$emit('on-timer-end');
      window.location.reload();
    },
    addTime() {
      const startTime = localStorage.getItem('startTime');
      this.startTime = moment(startTime);
      var startDate = new Date();
      var endDate   = new Date(this.endTime);
      var seconds = parseInt((endDate.getTime() - startDate.getTime()) / 1000);
      if(seconds <= 30) {
        this.endTime = moment(this.startTime).add(8, 'minutes');
      }
      localStorage.setItem('startTime', this.startTime);
    },
  },
  // watch: {
  //   currentime: (val) => {
  //     console.log("🚀 ~ file: timer.vue ~ line 120 ~ val", currentime)
  //   },
  // }
};
</script>
